<template>
  <div>
    <div class="min-table-h">
      <el-table :data="tableData">
        <!-- <el-table-column prop="id" label="id" width="50"> </el-table-column> -->
        <el-table-column prop="title" label="标题" width="300"></el-table-column>
        <!-- <el-table-column prop="lable" label="标签" width="100"></el-table-column> -->
        <el-table-column prop="lableType" label="seo词组"> </el-table-column>
        <!-- <el-table-column prop="introduce" label="简介"> </el-table-column> -->
        <el-table-column label="创建时间" width="130">
          <template v-slot="{ row }">{{ formdata(row.createTime) }}</template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="220">
          <template #default="scope">
            <el-button
              @click="handleClick(scope.row, 1)"
              type="primary"
              size="small"
              plain
              >查看</el-button
            >
            <el-button
              @click="handleClick(scope.row, 2)"
              type="primary"
              size="small"
              plain
              >编辑</el-button
            >
            <el-button
              @click="handleClick(scope.row, 3)"
              type="danger"
              size="small"
              plain
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage1"
      :page-sizes="[10, 50, 100, 150]"
      :page-size="criteria.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <Show :content="item" ref="show" />
  </div>
</template>

<script>
import { allArticle, deleteArticle } from "@/api/article";
import dayjs from 'dayjs' // ES 2015

import { deleteImage } from "@/api/upload";

import Show from "./components/show.vue";
export default {
  components: {
    Show,
  },
  data() {
    return {
      query: this.$route.query,
      tableData: [],
      criteria: {
        pageNo: 1,
        pageSize: 10,
      },
      total: "",
      item: "",
    };
  },
  computed: {
    formdata() {
      return function (time) {
        return dayjs(time).format("YYYY-MM-DD");
      };
    },
  },
  watch: {
    '$route' (to) {
      this.query = to.query
      this.init()
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log(Object.assign(this.query, this.criteria))
      allArticle(Object.assign(this.query, this.criteria)).then((res) => {
        console.log(res);
        this.tableData = res.data;
        this.total = parseInt(res.pageSize)
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.criteria.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.criteria.pageNo = val;
      this.init();
    },
    handleClick(item, type) {
      if (type === 1) {
        this.item = item;
        this.$refs.show.onfocus();
      } else if (type === 2) {
        this.$router.push({ name: "articleCreate", params: item });
      } else if (type === 3) {
        const imgList = this.getSrc(item.content);
        if (imgList.length > 0) {
          imgList.map((item) => {
            let colds = item.split("/");
            deleteImage({
              path: colds[colds.length - 2] + "/" + colds[colds.length - 1],
            }).then((res) => {
              if (res.status === 200) {
                this.$message.success({
                  message: res.data.mes,
                  type: "success",
                });
              }
            }); //服务器删除文件
          });
        }
        deleteArticle({ id: item.id }).then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.tableData.map((v, index) => {
              if (v.id === item.id) {
                this.tableData.splice(index, 1);
              }
            });
          }
        });
      }
    },
    getSrc(html) {
      console.log(html);
      var imgReg = /<img.*?(?:>|\/>)/gi;
      // 匹配src属性
      var srcReg = /src=[\\"]?([^\\"]*)[\\"]?/i;
      var arr = html.match(imgReg);
      let imgs = [];
      if (arr) {
        for (let i = 0; i < arr.length; i++) {
          var src = arr[i].match(srcReg)[1];
          imgs.push(src);
        }
      }
      return imgs;
    },
  },
};
</script>
<style scoped>
.min-table-h {
  height: calc(100vh - 190px);
  overflow: auto;
}
.el-pagination {
  padding-top: 20px;
}
</style>