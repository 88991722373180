<template>
  <el-drawer title="我是标题" v-model="drawer" size='60%' :with-header="false">
    <div class="head">{{content.title}}</div>
    <div class="content" v-html="content.content"></div>
  </el-drawer>
</template>

<script>

export default {
  props: {
    content: Object,
    required: function(){
        return {}
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  mounted(){
   
    
  },
  methods:{
      onfocus(){
        
          this.drawer = true
       
      }
  }
};
</script>
<style scoped>
.head{padding: 20px;font-size: 16px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.content{padding: 0px 20px 20px 20px;overflow: auto;height: calc(100vh - 56px);}
</style>