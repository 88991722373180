import request from '@/axios/request';
import qs from "qs";

export function addArticle(data) {
    return request({
      url: '/api/article/addArticle',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}

export function allArticle(data) {
  return request({
    url: '/api/article/allArticle',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}
export function deleteArticle(data) {
  return request({
    url: '/api/article/deleteArticle',
    method: 'post',
    data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
  });
}